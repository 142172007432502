// extracted by mini-css-extract-plugin
export var fontFamilyHeader = "menu-module--font-family-header--1t4C1";
export var btn = "menu-module--btn--NWRgu";
export var contentContainer = "menu-module--content-container--xUJMH";
export var Menu = "menu-module--Menu--fLwcr";
export var MenuRight = "menu-module--MenuRight--JD21l";
export var MenuLink = "menu-module--MenuLink--1ItkR";
export var MenuLinkWithBorder = "menu-module--MenuLinkWithBorder--2S-oV";
export var HamburgerButton = "menu-module--HamburgerButton--1BE93";
export var Hamburger = "menu-module--Hamburger--2pR3e";
export var Bar = "menu-module--Bar--3OR6_";
export var HamburgerActive = "menu-module--HamburgerActive--9Q_Zn";